import React from "react"
import './index.scss';

const Logo = ({...props}) => (

  <svg
    version="1.1"
    id="Layer_1"
    xmlns="http://www.w3.org/2000/svg"
    x="0px"
    y="0px"
    viewBox="0 0 356.17 56.37"
    className="logo"
    // style="enable-background:new 0 0 356.17 56.37;"
  >

    <g>
      <g>
        <path
          fill={`${props.color}`}
          d="M4.71,35.24c-0.09,0.64-0.17,1.27-0.24,1.91c-0.07,0.63-0.1,1.27-0.1,1.91c0,1.59,0.25,3.11,0.75,4.56
			c0.5,1.45,1.22,2.72,2.15,3.81c0.93,1.09,2.09,1.95,3.47,2.59c1.39,0.64,2.96,0.95,4.73,0.95c1.63,0,3.19-0.28,4.67-0.85
			c1.47-0.57,2.79-1.32,3.95-2.25c1.16-0.93,2.13-2.01,2.93-3.24c0.8-1.22,1.35-2.52,1.67-3.88h2.59c-0.5,1.82-1.23,3.49-2.18,5.01
			s-2.11,2.84-3.47,3.95c-1.36,1.11-2.92,1.97-4.66,2.59c-1.75,0.61-3.67,0.92-5.76,0.92c-2.23,0-4.18-0.36-5.86-1.09
			s-3.08-1.72-4.19-3c-1.11-1.27-1.95-2.75-2.52-4.43s-0.85-3.45-0.85-5.31c0-3.27,0.43-6.29,1.29-9.06s2.09-5.18,3.68-7.22
			c1.59-2.04,3.54-3.64,5.86-4.8c2.32-1.16,4.9-1.74,7.76-1.74c2.04,0,3.86,0.34,5.45,1.02c1.59,0.68,2.93,1.62,4.02,2.83
			c1.09,1.2,1.92,2.63,2.49,4.29c0.57,1.66,0.85,3.44,0.85,5.35c0,0.91-0.05,1.78-0.14,2.62c-0.09,0.84-0.2,1.69-0.34,2.55H4.71z
			 M30.25,32.99c0.09-0.59,0.17-1.11,0.24-1.57c0.07-0.45,0.1-0.91,0.1-1.36c0-1.5-0.22-2.93-0.65-4.29
			c-0.43-1.36-1.08-2.55-1.94-3.57c-0.87-1.02-1.98-1.84-3.34-2.45c-1.36-0.61-2.95-0.92-4.77-0.92c-2.04,0-3.89,0.39-5.55,1.16
			c-1.66,0.77-3.1,1.82-4.33,3.13c-1.22,1.32-2.25,2.83-3.06,4.53s-1.43,3.49-1.84,5.35H30.25z"
        />
        <path
          fill={`${props.color}`}
          d="M49.32,32.24L62.8,17.4h3.41L50.68,34.36l9.67,18.05h-3.13l-8.65-16.14L33.72,52.4h-3.47l17.03-18.39
			L38.49,17.4h3.2L49.32,32.24z"
        />
        <path
          fill={`${props.color}`}
          d="M102.13,48.83c-3.56,3.11-8.34,4.66-14.34,4.66c-6.67,0-11.82-1.85-15.43-5.55c-3.61-3.7-5.41-8.93-5.41-15.7
			c0-3.95,0.61-7.71,1.84-11.27c1.23-3.56,2.98-6.71,5.28-9.43c2.29-2.72,5.1-4.89,8.41-6.5c3.31-1.61,7.04-2.42,11.17-2.42
			c2.63,0,5.07,0.35,7.32,1.06c2.25,0.71,4.2,1.75,5.86,3.13c1.66,1.39,2.96,3.13,3.92,5.24c0.95,2.11,1.43,4.58,1.43,7.39h-6.47
			c0-3.81-1.12-6.66-3.37-8.55c-2.25-1.88-5.14-2.83-8.68-2.83c-3.41,0-6.37,0.71-8.89,2.14c-2.52,1.43-4.62,3.28-6.3,5.55
			c-1.68,2.27-2.94,4.85-3.78,7.73s-1.26,5.8-1.26,8.75c0,2.36,0.3,4.52,0.89,6.47c0.59,1.95,1.47,3.62,2.66,5.01
			c1.18,1.39,2.67,2.46,4.46,3.24c1.79,0.77,3.92,1.16,6.37,1.16c3.99,0,7.24-1.06,9.74-3.17c2.5-2.11,4.27-5.1,5.31-8.96h6.47
			C108.1,41.44,105.7,45.72,102.13,48.83z"
        />
        <path
          fill={`${props.color}`}
          d="M120.01,39.6c0,1.36,0.25,2.58,0.75,3.64c0.5,1.07,1.18,1.95,2.04,2.66c0.86,0.7,1.84,1.25,2.93,1.63
			c1.09,0.38,2.23,0.58,3.41,0.58c2.59,0,4.72-0.58,6.4-1.74c1.68-1.16,2.93-2.83,3.74-5.01h5.79c-0.45,1.95-1.18,3.67-2.18,5.14
			c-1,1.48-2.2,2.7-3.61,3.68c-1.41,0.98-2.97,1.71-4.7,2.21c-1.73,0.5-3.54,0.75-5.45,0.75c-2.91,0-5.32-0.44-7.25-1.33
			c-1.93-0.88-3.46-2.02-4.6-3.4c-1.13-1.38-1.93-2.91-2.38-4.56c-0.45-1.66-0.68-3.26-0.68-4.8c0-3.36,0.49-6.44,1.46-9.23
			c0.98-2.79,2.29-5.19,3.95-7.19c1.66-2,3.59-3.54,5.79-4.63c2.2-1.09,4.51-1.63,6.91-1.63c5.18,0,8.96,1.36,11.34,4.09
			c2.38,2.72,3.57,6.56,3.57,11.51c0,0.91-0.07,1.77-0.21,2.59c-0.14,0.82-0.25,1.47-0.34,1.97h-26.49
			C120.08,37.53,120.01,38.56,120.01,39.6z M141.23,27.78c-0.25-1.2-0.7-2.27-1.36-3.2c-0.66-0.93-1.53-1.68-2.62-2.25
			c-1.09-0.57-2.43-0.85-4.02-0.85c-1.68,0-3.19,0.26-4.53,0.78c-1.34,0.52-2.51,1.24-3.51,2.15c-1,0.91-1.84,1.96-2.52,3.17
			c-0.68,1.2-1.22,2.49-1.63,3.85h20.43C141.56,30.2,141.48,28.99,141.23,27.78z"
        />
        <path
          fill={`${props.color}`}
          d="M165.78,3.77L155.56,52.4h-5.72l10.15-48.63H165.78z"
        />
        <path
          fill={`${props.color}`}
          d="M180.9,3.77L170.68,52.4h-5.72l10.15-48.63H180.9z"
        />
        <path
          fill={`${props.color}`}
          d="M184.23,35.24c-0.09,0.64-0.17,1.27-0.24,1.91c-0.07,0.63-0.1,1.27-0.1,1.91c0,1.59,0.25,3.11,0.75,4.56
			c0.5,1.45,1.21,2.72,2.14,3.81c0.93,1.09,2.09,1.95,3.47,2.59c1.38,0.64,2.96,0.95,4.73,0.95c1.63,0,3.19-0.28,4.66-0.85
			s2.79-1.32,3.95-2.25c1.16-0.93,2.13-2.01,2.93-3.24c0.79-1.22,1.35-2.52,1.67-3.88h2.59c-0.5,1.82-1.23,3.49-2.18,5.01
			c-0.95,1.52-2.11,2.84-3.47,3.95c-1.36,1.11-2.92,1.97-4.67,2.59c-1.75,0.61-3.67,0.92-5.76,0.92c-2.23,0-4.18-0.36-5.86-1.09
			c-1.68-0.73-3.08-1.72-4.19-3c-1.11-1.27-1.95-2.75-2.52-4.43c-0.57-1.68-0.85-3.45-0.85-5.31c0-3.27,0.43-6.29,1.29-9.06
			c0.86-2.77,2.09-5.18,3.68-7.22s3.54-3.64,5.86-4.8c2.32-1.16,4.9-1.74,7.76-1.74c2.04,0,3.86,0.34,5.45,1.02
			c1.59,0.68,2.93,1.62,4.02,2.83s1.92,2.63,2.49,4.29c0.57,1.66,0.85,3.44,0.85,5.35c0,0.91-0.05,1.78-0.14,2.62
			c-0.09,0.84-0.21,1.69-0.34,2.55H184.23z M209.77,32.99c0.09-0.59,0.17-1.11,0.24-1.57c0.07-0.45,0.1-0.91,0.1-1.36
			c0-1.5-0.22-2.93-0.65-4.29c-0.43-1.36-1.08-2.55-1.94-3.57c-0.86-1.02-1.98-1.84-3.34-2.45c-1.36-0.61-2.95-0.92-4.77-0.92
			c-2.04,0-3.89,0.39-5.55,1.16c-1.66,0.77-3.1,1.82-4.33,3.13c-1.22,1.32-2.25,2.83-3.06,4.53c-0.82,1.7-1.43,3.49-1.84,5.35
			H209.77z"
        />
        <path
          fill={`${props.color}`}
          d="M222.37,17.4h2.59l-1.91,8.38h0.14c1.27-2.63,3.01-4.82,5.21-6.57c2.2-1.74,4.87-2.62,8-2.62
			c0.36,0,0.73,0.01,1.09,0.03c0.36,0.03,0.73,0.06,1.09,0.1l-0.75,2.72c-0.68-0.18-1.39-0.27-2.11-0.27
			c-2.54,0-4.69,0.66-6.44,1.98c-1.75,1.32-3.21,2.94-4.39,4.87c-1.18,1.93-2.11,4.01-2.79,6.23c-0.68,2.23-1.23,4.22-1.63,5.99
			L217.4,52.4h-2.59L222.37,17.4z"
        />
        <path
          fill={`${props.color}`}
          d="M237.77,27.48c0.91-3.9,2.65-6.7,5.24-8.38c2.59-1.68,5.86-2.52,9.81-2.52c0.86,0,1.92,0.1,3.17,0.31
			c1.25,0.2,2.44,0.61,3.58,1.22c1.13,0.61,2.1,1.45,2.89,2.52c0.79,1.07,1.19,2.49,1.19,4.26c0,0.77-0.11,1.78-0.34,3.03
			c-0.23,1.25-0.48,2.49-0.75,3.71c-0.27,1.22-0.53,2.34-0.78,3.34c-0.25,1-0.4,1.63-0.44,1.91c-0.23,0.91-0.48,1.91-0.75,3
			c-0.27,1.09-0.52,2.16-0.75,3.2s-0.42,2.01-0.58,2.89c-0.16,0.89-0.24,1.58-0.24,2.08c0,0.64,0.16,1.17,0.48,1.6
			c0.32,0.43,0.8,0.65,1.43,0.65c0.36,0,0.75-0.04,1.16-0.14c0.41-0.09,0.8-0.18,1.16-0.27l-0.41,2.32
			c-0.5,0.14-1.01,0.23-1.53,0.27c-0.52,0.04-1.01,0.07-1.46,0.07c-1.18,0-2.04-0.41-2.59-1.23c-0.54-0.82-0.82-1.61-0.82-2.38
			c0-0.36,0.07-0.83,0.21-1.4c0.14-0.57,0.25-1.08,0.34-1.53h-0.14c-0.68,1.27-1.55,2.36-2.59,3.27c-1.04,0.91-2.16,1.66-3.34,2.25
			c-1.18,0.59-2.41,1.02-3.68,1.29c-1.27,0.27-2.47,0.41-3.61,0.41c-1.5,0-2.91-0.17-4.22-0.51c-1.32-0.34-2.46-0.89-3.44-1.63
			c-0.98-0.75-1.75-1.71-2.32-2.89c-0.57-1.18-0.85-2.63-0.85-4.36c0-2.22,0.43-4.04,1.29-5.45c0.86-1.41,2.01-2.5,3.44-3.27
			c1.43-0.77,3.06-1.3,4.9-1.6c1.84-0.3,3.76-0.47,5.75-0.51c2.45-0.09,4.5-0.26,6.13-0.51c1.63-0.25,2.95-0.63,3.95-1.16
			c1-0.52,1.71-1.21,2.14-2.07c0.43-0.86,0.65-1.95,0.65-3.27c0-2.18-0.67-3.92-2.01-5.21c-1.34-1.29-3.6-1.94-6.78-1.94
			c-3.13,0-5.7,0.69-7.7,2.08c-2,1.39-3.38,3.57-4.16,6.57H237.77z M259.7,32.65h-0.14c-0.5,0.5-1.2,0.9-2.11,1.19
			c-0.91,0.29-1.88,0.54-2.93,0.75c-1.04,0.21-2.1,0.35-3.17,0.44c-1.07,0.09-1.96,0.16-2.69,0.2c-1.63,0.05-3.24,0.16-4.84,0.34
			c-1.59,0.18-3.01,0.57-4.26,1.16c-1.25,0.59-2.26,1.44-3.03,2.56c-0.77,1.11-1.16,2.62-1.16,4.53c0,1.32,0.25,2.43,0.75,3.34
			c0.5,0.91,1.16,1.65,1.98,2.21c0.82,0.57,1.76,0.98,2.83,1.23c1.06,0.25,2.19,0.37,3.37,0.37c2.09,0,3.92-0.36,5.48-1.09
			c1.57-0.73,2.9-1.71,4.02-2.96c1.11-1.25,2.04-2.69,2.79-4.33c0.75-1.63,1.37-3.36,1.87-5.18L259.7,32.65z"
        />
        <path
          fill={`${props.color}`}
          d="M269.71,17.4h6.47l2.38-10.96h2.59l-2.38,10.96h7.49l-0.55,2.25h-7.36l-5.31,24.38
			c-0.05,0.09-0.09,0.29-0.14,0.58c-0.04,0.3-0.1,0.61-0.17,0.95c-0.07,0.34-0.13,0.67-0.17,0.99c-0.05,0.32-0.07,0.54-0.07,0.68
			c0,1,0.31,1.74,0.92,2.21c0.61,0.48,1.62,0.71,3.03,0.71c1.27,0,2.57-0.07,3.88-0.2l-0.48,2.25c-0.45,0-1.07,0.03-1.84,0.1
			c-0.77,0.07-1.57,0.1-2.38,0.1c-2.04,0-3.51-0.45-4.39-1.36c-0.89-0.91-1.33-2.2-1.33-3.88c0-0.45,0.07-1,0.2-1.63
			c0.14-0.64,0.25-1.2,0.34-1.7l5.31-24.18h-6.61L269.71,17.4z"
        />
        <path
          fill={`${props.color}`}
          d="M304.37,16.58c4.36,0,7.75,1.2,10.18,3.61c2.43,2.4,3.64,5.79,3.64,10.15c0,2.68-0.4,5.38-1.19,8.11
			c-0.79,2.72-1.97,5.18-3.54,7.35c-1.57,2.18-3.52,3.96-5.86,5.35c-2.34,1.38-5.03,2.08-8.07,2.08c-2.41,0-4.48-0.35-6.23-1.06
			s-3.2-1.7-4.36-3c-1.16-1.29-2.02-2.84-2.59-4.63c-0.57-1.79-0.85-3.8-0.85-6.03c0-2.63,0.43-5.26,1.29-7.9
			c0.86-2.63,2.1-4.98,3.71-7.05c1.61-2.06,3.59-3.74,5.93-5.04C298.78,17.23,301.42,16.58,304.37,16.58z M304.92,18.83
			c-2.68-0.04-5.06,0.51-7.15,1.67c-2.09,1.16-3.85,2.67-5.28,4.53c-1.43,1.86-2.52,3.96-3.27,6.3c-0.75,2.34-1.12,4.67-1.12,6.98
			c0,1.77,0.22,3.42,0.65,4.94c0.43,1.52,1.11,2.86,2.04,4.02c0.93,1.16,2.11,2.07,3.54,2.73c1.43,0.66,3.14,0.99,5.14,0.99
			c2.68,0,5.03-0.65,7.05-1.94c2.02-1.29,3.7-2.94,5.04-4.94c1.34-2,2.35-4.21,3.03-6.64c0.68-2.43,1.02-4.78,1.02-7.05
			c0-3.45-0.87-6.22-2.59-8.31C311.3,20.01,308.59,18.92,304.92,18.83z"
        />
        <path
          fill={`${props.color}`}
          d="M327.8,17.4h2.59l-1.91,8.38h0.14c1.27-2.63,3.01-4.82,5.21-6.57c2.2-1.74,4.87-2.62,8-2.62
			c0.36,0,0.73,0.01,1.09,0.03c0.36,0.03,0.73,0.06,1.09,0.1l-0.75,2.72c-0.68-0.18-1.39-0.27-2.11-0.27
			c-2.54,0-4.69,0.66-6.44,1.98c-1.75,1.32-3.21,2.94-4.39,4.87c-1.18,1.93-2.11,4.01-2.79,6.23c-0.68,2.23-1.23,4.22-1.63,5.99
			l-3.06,14.17h-2.59L327.8,17.4z"
        />
      </g>
      <g>
        <path
          fill={`${props.color}`}
          d="M346.28,16.84h3.29l-0.13,0.6h-1.27l-0.71,3.39h-0.7l0.7-3.39h-1.31L346.28,16.84z"
        />
        <path
          fill={`${props.color}`}
          d="M350.01,16.84h0.96l0.44,3.04h0.01l1.73-3.04h0.99l-0.82,3.99h-0.67l0.69-3.2h-0.01l-1.81,3.2h-0.55l-0.5-3.2
			h-0.01l-0.61,3.2h-0.67L350.01,16.84z"
        />
      </g>
    </g>
  </svg>
)

export default Logo
