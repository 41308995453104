import React from "react"
import "./index.scss"

function PatentHeader() {
  return (
    <div className="patent-bar">
      <h2>PATENT PENDING</h2>
    </div>
  )
}

export default PatentHeader
