import React, { useState, useEffect } from "react"
import { Link } from "gatsby"
import MenuList from "@material-ui/core/MenuList"
import MenuItem from "@material-ui/core/MenuItem"
import Popper from "@material-ui/core/Popper"
import "./index.scss"

const NavItem = ({ ...props }, i) => {
  const [isOpen, setIsOpen] = useState(false)
  // const [isMobile, setIsMobile] = useState()
  // const [windowSize, setWindowSize] = useState()

  // useEffect(() => {
  //   setWindowSize(window.innerWidth)
  // }, [])

  // useEffect(() => {
  //   window.addEventListener("resize", () => setWindowSize(window.innerWidth))
  //   windowSize > 769
  //     ? setIsMobile(false) && setIsClosed(false)
  //     : setIsMobile(true)
  // }, [])

  // const handleSubMenu = () => {
  //   (isMobile ? null : setIsOpen(true))();
  //   // setIsOpen(false);
  // }

  return (
    <section
      className="nav-item"
      onMouseOver={() => setIsOpen(true)}
      onMouseLeave={() => setIsOpen(false)}
      key={i}
    >
      {props.url ? (
        <Link
          className="nav-item__link"
          to={props.url}
          activeClassName="active"
        >
          <span>{props.title}</span>
        </Link>
      )
      : <span className="nav-item__link">{props.title}</span>}
      {props.subLinks && (
        <Popper open={isOpen} disablePortal className="subnav">
          <MenuList className="subnav-list">
            {props.subLinks
              ? props.subLinks.map(sub => (
                  <a
                    className="subnav-item__link"
                    href={sub.url}
                    activeClassName="active"
                  >
                    <MenuItem>{sub.title}</MenuItem>
                  </a>
                ))
              : null}
          </MenuList>
        </Popper>
      )}
    </section>
  )
}

export default NavItem
