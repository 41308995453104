import React, { useState, useEffect } from "react"
import NavItem from "./nav-item"
import MobileNavItem from "./MobileNavItem"
import Logo from "./Logo"
import { Link } from "gatsby"
import "./header.scss"
import pressRelease from "src/images/excellerator-press-release.pdf"

const Header = () => {
  const document = pressRelease
  const [isClosed, setIsClosed] = useState(true)
  const [isMobile, setIsMobile] = useState()
  const [windowSize, setWindowSize] = useState()

  useEffect(() => {
    setIsClosed(true)
    setWindowSize(window.innerWidth)
  }, [])

  useEffect(() => {
    window.addEventListener("resize", () => setWindowSize(window.innerWidth))
    windowSize > 920
      ? setIsMobile(false) && setIsClosed(false)
      : setIsMobile(true)
  })

  return (
    <>
      {!isMobile ? (
        <header className="header header--desktop">
          <div className="header__contain">
            <Link className="header__logo" to="/">
              <Logo color="white" />
            </Link>
            <nav>
              <NavItem title="Home" url="/" />
              <NavItem
                title="Development"
                subLinks={[
                  {
                    title: "Creation - History",
                    url: "/development/history",
                  },
                  {
                    title: "FDA Clearance",
                    url: "/development/fda-clearance",
                  },
                  {
                    title: "IFU - QRG",
                    url: "/development/quick-reference-guide",
                  },
                  {
                    title: "Press Release",
                    url:
                      "/static/excellerator-press-release-e270d8e86aff7de35935ba80b9e2977f.pdf",
                  },
                ]}
              />
              <NavItem
                title="a Better Way"
                subLinks={[
                  {
                    title: "Importance",
                    url: "/a-better-way/importance/",
                  },
                  {
                    title: "Innovative Design",
                    url: "/a-better-way/innovative-design",
                  },
                  {
                    title: "How it Works",
                    url: "/a-better-way/using-excellerator",
                  },
                  {
                    title: "Watch Video",
                    url: "/demo-video/",
                  },
                ]}
              />
              <NavItem
                title="Women's Health - Impact"
                subLinks={[
                  {
                    title: "Cervical Cancer",
                    url: "/womens-health-impact/cervical-cancer-screening",
                  },
                  {
                    title: "HPV & STD Screening",
                    url: "/womens-health-impact/hpv-std-screening",
                  },
                  {
                    title: "Pilot Data",
                    url: "/womens-health-impact/pilot-study",
                  },
                  // {
                  //   title: "Real World Data",
                  //   url: "/womens-health-impact/real-world-data",
                  // },
                  // {
                  //   title: "FDA Validation Data",
                  //   url: "/womens-health-impact/fda-validation-study",
                  // },
                  // {
                  //   title: "Clinical Results",
                  //   url: "/healthcare-professionals/clinical-results",
                  // },
                  // {
                  //   title: "Getting ExCELLerator",
                  //   url: "/healthcare-professionals/getting-excellerator",
                  // },
                  // {
                  //   title: "STD Screening",
                  //   url: "/healthcare-professionals/std-screening",
                  // },
                ]}
              />
              <NavItem
                title="Let's Do This!"
                subLinks={[
                  {
                    title: "Contact",
                    url: "/contact/",
                  },
                  {
                    title: "IFU - QRG",
                    url: "/development/quick-reference-guide",
                  },
                ]}
              />
            </nav>
          </div>
        </header>
      ) : (
        <>
          <header
            className={`header header--mobile ${isClosed ? "" : "active"}`}
          >
            <div onClick={() => setIsClosed(true)} className="header__close">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="24"
                height="24"
                fill="#ffffff"
                viewBox="0 0 24 24"
              >
                <path d="M24 20.188l-8.315-8.209 8.2-8.282-3.697-3.697-8.212 8.318-8.31-8.203-3.666 3.666 8.321 8.24-8.206 8.313 3.666 3.666 8.237-8.318 8.285 8.203z" />
              </svg>
            </div>
            <div className="header__contain">
              <nav>
                <MobileNavItem title="Home" url="/" />
                <MobileNavItem
                  title="Development"
                  subLinks={[
                    {
                      title: "Creation - History",
                      url: "/development/history",
                    },
                    {
                      title: "FDA Clearance",
                      url: "/development/fda-clearance",
                    },
                    {
                      title: "IFU - QRG",
                      url: "/development/quick-reference-guide",
                    },
                    {
                      title: "Press Release",
                      url:
                        "/static/excellerator-press-release-e270d8e86aff7de35935ba80b9e2977f.pdf",
                    },
                  ]}
                />
                <MobileNavItem
                  title="a Better Way"
                  subLinks={[
                    {
                      title: "Importance",
                      url: "/a-better-way/importance/",
                    },
                    {
                      title: "Innovative Design",
                      url: "/a-better-way/innovative-design",
                    },
                    {
                      title: "How it Works",
                      url: "/a-better-way/using-excellerator",
                    },
                    {
                      title: "Watch Video",
                      url: "/demo-video/",
                    },
                  ]}
                />
                <MobileNavItem
                  title={
                    <div>
                      Women's Health
                      <br />
                      Impact
                    </div>
                  }
                  subLinks={[
                    {
                      title: "Cervical Cancer",
                      url: "/womens-health-impact/cervical-cancer-screening",
                    },
                    {
                      title: "HPV & STD Screening",
                      url: "/womens-health-impact/hpv-std-screening",
                    },
                    {
                      title: "Pilot Data",
                      url: "/womens-health-impact/pilot-study",
                    },
                    // {
                    //   title: "Real World Data",
                    //   url: "/womens-health-impact/real-world-data",
                    // },
                    // {
                    //   title: "FDA Validation Data",
                    //   url: "/womens-health-impact/fda-validation-study",
                    // },
                    // {
                    //   title: "Clinical Results",
                    //   url: "/healthcare-professionals/clinical-results",
                    // },
                    // {
                    //   title: "Getting ExCELLerator",
                    //   url: "/healthcare-professionals/getting-excellerator",
                    // },
                    // {
                    //   title: "STD Screening",
                    //   url: "/healthcare-professionals/std-screening",
                    // },
                  ]}
                />
                <MobileNavItem
                  title="Let's Do This!"
                  subLinks={[
                    {
                      title: "Contact",
                      url: "/contact/",
                    },
                    {
                      title: "IFU - QRG",
                      url: "/development/quick-reference-guide",
                    },
                  ]}
                />
              </nav>
            </div>
          </header>
          <div className="header-toggle" onClick={() => setIsClosed(false)}>
            <svg
              height="32px"
              id="Layer_1"
              viewBox="0 0 32 32"
              width="32px"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path d="M4,10h24c1.104,0,2-0.896,2-2s-0.896-2-2-2H4C2.896,6,2,6.896,2,8S2.896,10,4,10z M28,14H4c-1.104,0-2,0.896-2,2  s0.896,2,2,2h24c1.104,0,2-0.896,2-2S29.104,14,28,14z M28,22H4c-1.104,0-2,0.896-2,2s0.896,2,2,2h24c1.104,0,2-0.896,2-2  S29.104,22,28,22z" />
            </svg>
          </div>
        </>
      )}
    </>
  )
}

export default Header
