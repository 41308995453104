import React, { useState, useEffect } from "react"
import { Link } from "gatsby"
import MenuList from "@material-ui/core/MenuList"
import MenuItem from "@material-ui/core/MenuItem"
import toggle from "src/images/toggle.svg"
import "./index.scss"

const MobileNavItem = ({ ...props }, i) => {
  const [isOpen, setIsOpen] = useState(false)

  return (
    <section className="nav-item" key={i}>
      {props.url ? (
        <div className="mobile-nav-item">
          <Link
            className="mobile-nav-item__link"
            to={props.url}
            activeClassName="active"
          >
            <span>{props.title}</span>
          </Link>
          
            <div
              className={`mobile-nav-item__toggle ${isOpen ? "active" : ""}`}
              onClick={() => setIsOpen(!isOpen)}
            >
              {props.subLinks && <img src={toggle} alt="toggle menu" />}
            </div>
        </div>
      ) : (
        <div className="mobile-nav-item">
          <span className="mobile-nav-item__link">{props.title}</span>
          <div
            className="mobile-nav-item__toggle"
            onClick={() => setIsOpen(!isOpen)}
          >
            {props.subLinks && <img src={toggle} alt="toggle menu" /> }
          </div>
        </div>
      )}
      {isOpen && (
        <MenuList className="mobile-nav-item__subnav">
          {props.subLinks
            ? props.subLinks.map(sub => (
                <MenuItem className="mobile-nav-item__subnav-item">
                  <Link
                    className="subnav-item__link"
                    to={sub.url}
                    activeClassName="active"
                  >
                    {sub.title}
                  </Link>
                </MenuItem>
              ))
            : null}
        </MenuList>
      )}
    </section>
  )
}

export default MobileNavItem
