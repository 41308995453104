import React from "react"
import { graphql, StaticQuery } from "gatsby"
import Img from "gatsby-image"
import { Link } from "gatsby"
import "./index.scss"
//Primary Query Enabled 11-3-2020
const Footer = data => (
  <StaticQuery
    query={graphql`
      query {
        logo: file(relativePath: { eq: "excell-logo.png" }) {
          childImageSharp {
            fixed(quality: 100, width: 150) {
              ...GatsbyImageSharpFixed
            }
          }
        }
      }
    `}
    render={data => {
      const logo = data.logo.childImageSharp.fixed
      return (
        <footer>
          <div className="wrapper">
            <div className="footer">
              {/* <Img fixed={data.file.childImageSharp.fixed} /> this line is causing a stack collapse but unsure why. */}
              <div className="footer-contain">
                <div className="footer__info-logo">
                  <Img fixed={logo} />
                </div>
                <div className="footer-contain-items">
                  <p className="footer__legal">
                    &copy; {new Date().getFullYear()} exCell. All Rights
                    Reserved. <Link to="/privacy-policy">Privacy Policy</Link>
                  </p>
                </div>
              </div>
            </div>
          </div>
        </footer>
      )
    }}
  />
)
// Secondary query Disabled 11-3-2020
// export const query = graphql`
//   query {
//     file(relativePath: { eq: "./images/excell-logo.png" }) {
//       childImageSharp {
//         # Specify the image processing specifications right in the query.
//         # Makes it trivial to update as your page's design changes.
//         fixed(width: 125, height: 125) {
//           ...GatsbyImageSharpFixed
//         }
//       }
//     }
//   }
// `

export default Footer
